<template>
  <CrudCardTable
    title="Customizers"
    :items="instances"
    :headers="headers"
    :button-to="{name: 'App-MenuCreator-Customizers-New'}"
  >
    <template #item.name="{ item }">
      <router-link :to="{ name: 'App-MenuCreator-Customizers-id', params: { id: item.id } }">
        {{ item.name }}
      </router-link>
    </template>
    <template #item.customizerItems="{ item }">
      <v-chip-group>
        <v-chip
          v-for="customizerItem in item.customizerItems"
          :key="customizerItem.$id"
        >
          {{ customizerItem.item ? customizerItem.item.name : "" }}
        </v-chip>
      </v-chip-group>
    </template>
  </CrudCardTable>
</template>

<script>
import { mapGetters } from 'vuex'
import CrudCardTable from '@/components/CrudCardTable'

export default {
  name: 'CustomizerList',
  components: { CrudCardTable },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Required', value: 'required' },
        { text: 'Limit', value: 'limit' },
        { text: 'Choices', value: 'customizerItems' }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['customizers']),
    instances () {
      return this.customizers.all()
    }
  }
}
</script>
